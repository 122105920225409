<template>
  <div class="htbox" @click="handleFuc2(moduleName)">
    <img :src="filteru(moduleName)" />
    <p>{{moduleName}}</p>
  </div> 
</template>

<script>
import { apsAddress, wmsAddress } from '@/api'
export default {
  name: "ModuleBox",
  props:{
    moduleName: String,
    urlAddress: String,
  },
  data(){
    return {
      picLists:[
        { moduleId:'001', moduleName:'机器人调度', imgUrl:require("@/assets/images/common/homep1.png")},
        { moduleId:'002', moduleName:'WMS', imgUrl:require("@/assets/images/common/homep5.png") },
        { moduleId:'003', moduleName:'XOS管理', imgUrl:require("@/assets/images/common/homep2.png") },
        { moduleId:'004', moduleName:'APS先进排程', imgUrl:require("@/assets/images/common/homep3.png") },
        { moduleId:'005', moduleName:'MES', imgUrl:require("@/assets/images/common/homep4.png") },
      ]
    }
  },
  mounted() {

  },
  methods:{
    filteru(param){
      const result = this.picLists.find(({moduleName}) => moduleName === param);
      return result ? result.imgUrl : require("@/assets/images/common/homep1.png");
    },
    handleFuc(data) {
      data ? window.open(data, '_blank'): ''
    },
     handleFuc2(moduleName){
      switch (moduleName) {
        case 'APS先进排程':
          this.aps();
          break;
        case 'WMS':
          this.wms();
          break;
        default:
          break;
      }
    },

     async aps() {
      await apsAddress().then(({ code, data }) => {
        if (code === '200') {
          data ? window.open(data, '_blank') : ''
          
        }
      })
    },
    async wms() {
      await wmsAddress().then(({ code, data }) => {
        if (code === '200') {
          data ? window.open(data, '_blank') : ''
          
        }
      })
    },

  }

}
</script>

<style lang="scss" scoped>
.htbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #232323;
  width: 400px;
  height: 124px;
  border-radius: 8px;
  padding: 30px;
  margin: 0 30px 30px 0;
  font-weight: bold;
  font-size: 20px;
  img {
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
}
</style>