<template>
  <div class="homewrap">
    <section class="home-content">
      <div class="topbox">
        <moduleBox v-for="item in userModules" :key="item.id"  @click="clickHanble(item)" :module-name="item.moduleName" ></moduleBox>
      </div>
    </section>
  </div>
</template>

<script>
import moduleBox from './components/ModuleBox.vue'
import { mapState } from "vuex"

export default {
  name: "home",
  data() {
    return {
      userModules: [],
      ws:null,
    };
  },
  computed: {
    ...mapState('userPermission', ['customerModule'])
  },
  created() {
    this.initHandle()
  },
  components:{
    moduleBox
  },
  methods: {
    async initHandle() {
      this.userModules = this.customerModule
    },
    clickHanble(data) {
      window.open(data.urlAddress)
    }
  },
};
</script>

<style lang="scss" scoped>
.home-ban {
  display: flex;
  padding: 32px 32px;
  background: #232323;
}
.home-box {
  text-align: left;
  margin: 0 40px;
  width: 62%;
  h2 {
    font-size: 28px;
  }
  p {
    width: 780px;
    font-size: 14px;
  }
}
.home-list {
  display: flex;
  align-items: center;
  .line {
    border-right: 1px solid #e8e8e8;
    height: 50px;
    width: 2px;
    margin: 0 10px;
  }
}
.home-ban section {
  flex: 0 1 1;
}
.mbox {
  margin: 0 20px;
  h3 {
    font-size: 40px;
    color: #2deb79;
    .mdeil {
      font-size: 18px;
      color: #b9b9b9;
    }
  }
  p {
    font-size: 18px;
  }
}
.home-content {
  padding: 30px;
}
.topbox {
  display: flex;
  flex-wrap: wrap;
  .htbox{
    cursor: pointer;
  }
}

</style>
